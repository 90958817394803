<template>
  <span>
    <v-container fluid>
      <v-overlay :opacity="100" :value="!ready" absolute color="white">
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        />
      </v-overlay>

      <v-row>
        <v-col cols="12" class="d-flex px-0 mx-0">
          <h2>{{ $t('proccess.titles.generalRules') }}</h2>
        </v-col>
        <v-col cols="12" :md="6" class="d-flex pl-0 ml-0">
          <v-card class="my-3 pa-6"
                  outlined
                  width="100%"
          >
            <v-row>
              <v-col cols="12">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="isBalanceActive" color="primary" :disabled="!isProcessing">
                  <template v-slot:label>
                    <label class="pt-1" ref="label">
                      {{ $t('proccess.fields.massBalanceConfiguration') }}
                    </label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="4">
                <v-currency-field
                  :default-value="null"
                  :value-as-integer="false"
                  :disabled="!massBalanceConfiguration.isActive"
                  :decimal-length="2"
                  :auto-decimal-mode="false"
                  locale="en"
                  small
                  flat
                  outlined
                  dense
                  color="primary"
                  clearable
                  required
                  class="required"
                  :rules="onlyCurrencyFormat"
                  v-model="massBalanceConfiguration.convertionFactor"
                  :hide-details="!massBalanceConfiguration.isActive"
                >
                  <template v-slot:label>
                    {{$t('proccess.fields.percent')}} <strong v-if="massBalanceConfiguration.isActive" class="red--text">*</strong>
                  </template>
                </v-currency-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" :md="6" class="d-flex pr-0 mr-0">
          <v-card class="my-3 pa-6"
                  outlined
                  width="100%"
          >
            <v-row>
              <v-col cols="12">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="activeRangeDays" color="primary" @change="handleRangeDaysSwitch">
                  <template v-slot:label>
                    <label class="pt-1" ref="label">
                      {{ $t('proccess.fields.activeRangeDays') }}
                    </label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="4">
                <v-form ref="formDateRange" v-model="validDateRange">
                  <v-text-field
                    type="number"
                    :disabled="!activeRangeDays"
                    small
                    flat
                    outlined
                    dense
                    color="primary"
                    clearable
                    required
                    class="required"
                    :min="1"
                    v-model.number="dateRange"
                    :hide-details="!activeRangeDays"
                    :rules="activeRangeDays ? requireAndMinMax(1, 365) : []"
                  >
                    <template v-slot:label>
                      {{$t('reports.fields.amount')}} <strong v-if="activeRangeDays" class="red--text">*</strong>
                    </template>
                  </v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12">
                 <v-alert
                   border="left"
                   type="info"
                   dense
                   text
                 >
                  {{ $t('proccess.fields.activeRangeDaysAlert') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex px-0 mx-0">
          <h2>{{ $t('proccess.titles.customRules') }}</h2>
        </v-col>
        <v-col cols="12" class="d-flex justify-end px-0 mx-0">
          <v-btn color="primary" elevation="0" @click="addRule">
            {{ $t('general.buttons.new') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end flex-row align-center align-content-center">
        <v-col cols="2" class="ma-0 pa-0">
          <v-select
            class="mx-1"
            :items="dateTimeOperators"
            :no-data-text="$t('general.fields.noDataAvailable')"
            :clearable="true"
            v-model="dateOperator"
            item-color="primary"
            dense
            flat
            outlined
            color="primary"
            hide-details
            :label="$t('structure.fields.dateConditions')"
            :disabled="!(rules.length > 0)"
          >
            <template v-slot:selection="{ item, index }">
              {{ $t(`proccess.operators.${item}`) }}
            </template>
            <template v-slot:item="{ item, index }">
              {{ $t(`proccess.operators.${item}`) }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="ma-0 pa-0">
          <v-form ref="formRules"
                  v-model="valid">
            <v-card v-if="!(rules.length > 0)"
                    class="my-3 pa-6"
                    outlined
            >
              <v-card-text class="text-lg-center">
                {{ $t('proccess.titles.withoutRules') }}
              </v-card-text>
            </v-card>
            <v-card v-for="(rule, index) in rules"
                    v-bind:key="index"
                    class="my-3 pa-6"
                    outlined>
                <v-card-title>
                  <div v-if="!ruleName[index]">
                    <span class="neuter--text">{{ rule.name }}</span>
                    <v-btn small icon elevation="0" class="mx-1">
                      <v-icon small color="neuter" @click="editRuleName(index)">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </div>

                  <div v-else>
                    <v-text-field v-model="rule.name" dense flat outlined color="primary" :rules="requireRules">
                      <template #append>
                        <v-icon color="neuter" @click="saveRuleName(index)">mdi-check</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <v-spacer></v-spacer>
                  <v-icon v-if="rule.compress"
                          small
                          @click="rule.compress = !rule.compress">
                    mdi-arrow-collapse-vertical
                  </v-icon>
                  <v-icon v-if="!rule.compress"
                          small
                          @click="rule.compress = !rule.compress">
                    mdi-arrow-split-horizontal
                  </v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="rule.compress">
                  <v-row>
                    <v-col cols="10">
                      <v-row>
                        <v-col cols="2">
                          <v-select
                            :items="type"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            v-model="rule.isInternal"
                            item-color="primary"
                            item-text="text"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            :rules="requireRules"
                            @change="onChangeType(rule)"
                          >
                            <template v-slot:label>
                              {{ $t("proccess.fields.type") }}
                              <strong class="red--text">*</strong>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            :items="processTypeEnum"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            :label="$t('proccess.fields.processType')"
                            v-model="rule.processType"
                            item-color="primary"
                            item-text="text"
                            item-value="value"
                            dense
                            flat
                            outlined
                            color="primary"
                            :disabled="rule.isInternal.value"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-combobox
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            :items="storagesTypesByCompany"
                            :label="$t('proccess.fields.stages')"
                            item-text="storage_type_name"
                            item-color="primary"
                            v-model="rule.stage"
                            class="required"
                            color="primary"
                            small
                            flat
                            outlined
                            dense
                            :disabled="rule.isInternal.value"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            :items="filteredProcess(rule)"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            v-model="rule.processToValidate"
                            item-color="primary"
                            item-text="proccess_name"
                            item-value="processVersionId"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            :loading="loading.process[index]"
                            :rules="rule.isInternal.value ? [] : requireRules"
                            :disabled="rule.isInternal.value"
                            @change="onSelectedProcess(rule, index)"
                          >
                            <template v-slot:label>
                              {{ $t("modules.name.Process") }}
                              <strong class="red--text" v-if="!rule.isInternal.value">*</strong>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="4" />
                        <v-col cols="2">
                          <v-select
                            :items="rule.groups"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            :label="$t('structure.fields.groups')"
                            v-model="rule.groupSelected"
                            item-color="primary"
                            item-text="name"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            @change="getStructures(rule)"
                            :disabled="loading.process[index]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            :items="rule.structures"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            v-model="rule.structureSelected"
                            item-color="primary"
                            item-text="name"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            :rules="requireRules"
                            @change="getOperatorsAndNestedValues(rule)"
                            :disabled="loading.process[index]"
                          >
                            <template v-slot:label>
                              {{ $t('structure.fields.structures') }}
                              <strong class="red--text">*</strong>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            :items="rule.operators"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            v-model="rule.operator"
                            item-color="primary"
                            dense
                            flat
                            outlined
                            color="primary"
                            :rules="requireRules"
                            :disabled="!(rule.structureSelected) || loading.process[index]"
                          >
                            <template v-slot:label>
                              {{ $t('structure.fields.conditions') }}
                              <strong class="red--text">*</strong>
                            </template>
                            <template v-slot:selection="{ item, index }">
                              {{ $t(`proccess.operators.${item}`) }}
                            </template>
                            <template v-slot:item="{ item, index }">
                              {{ $t(`proccess.operators.${item}`) }}
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2" v-if="rule.structureSelected && !rule.validateOwn">
                          <v-select
                            v-if="rule.structureSelected && selectableStrucutres.includes(rule.structureSelected.structure_type)"
                            :items="rule.nestedValues"
                            :multiple="rule.isMultiple"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            v-model="rule.dataToValidates"
                            item-color="primary"
                            item-text="value"
                            return-object
                            small-chips
                            dense
                            flat
                            outlined
                            color="primary"
                            :rules="requireRules"
                            :disabled="!(rule.structureSelected) || rule.validateOwn"
                          >
                            <template v-slot:label>
                              {{ $t('proccess.fields.nestedValues') }}
                              <strong class="red--text">*</strong>
                            </template>
                            <template v-slot:selection="{ item, index }">
                              <v-chip small v-if="index === 0">
                                <span>{{ item.value | truncate(5) }}</span>
                              </v-chip>
                              <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                              >(+{{ rule.dataToValidates.length - 1 }}
                                {{ $t('general.titles.others') }})</span
                              >
                            </template>
                          </v-select>
                          <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            v-if="rule.structureSelected && rule.structureSelected.structure_type === 'NUMBER'"
                            v-model="rule.dataToValidates[0].value"
                            :auto-decimal-mode="false"
                            :clearable="true"
                            color="primary"
                            locale="en"
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            onkeypress="return (event.charCode != 34)"
                            :rules="requireRules"
                            :disabled="!(rule.structureSelected) || rule.validateOwn"
                          >
                            <template v-slot:label>
                              {{ $t('proccess.fields.nestedValues') }}
                              <strong class="red--text">*</strong>
                            </template>
                          </v-currency-field>
                          <v-text-field
                            v-if="rule.structureSelected && ['TEXT', 'LOTE'].includes(rule.structureSelected.structure_type)"
                            :rules="!rule.validateOwn ? requireRules : []"
                            v-model="rule.dataToValidates[0].value"
                            dense
                            flat
                            outlined
                            color="primary"
                            :disabled="!(rule.structureSelected) || rule.validateOwn"
                          >
                            <template v-slot:label>
                              {{ $t('proccess.fields.nestedValues') }}
                              <strong v-if="!rule.validateOwn" class="red--text">*</strong>
                            </template>
                          </v-text-field>

                          <a-datetime-picker
                            v-if="rule.structureSelected && rule.structureSelected.structure_type === 'DATATIME'"
                            v-model="rule.dataToValidates[0].value"
                            :label="$t('proccess.fields.nestedValues')"
                            :required="!rule.validateOwn"
                            :rules="!rule.validateOwn ? requireRules : []"
                            :format="'dd / MM / yyyy HH:mm'"
                            :textFieldProps="{
                              'append-icon': 'mdi-calendar',
                              'color': 'secondary',
                              'outlined': true,
                              'dense': true,
                              'clearable': true,
                            }"
                            :datePickerProps="{
                              'color': 'secondary'
                            }"
                            :timePickerProps="{
                              'color': 'secondary'
                            }"
                          />

                          <a-date-picker  v-if="rule.structureSelected && rule.structureSelected.structure_type === 'DATA'"
                                          v-model="rule.dataToValidates[0].value"
                                          :label="$t('proccess.fields.nestedValues')"
                                          :required="!rule.validateOwn"
                                          :rules="!rule.validateOwn ? requireRules : []"
                                          :textFieldProps="{ outlined: true, label: $t('proccess.fields.nestedValues') }"
                          />

                          <a-time-picker v-if="rule.structureSelected && rule.structureSelected.structure_type === 'TIME'"
                                         v-model="rule.dataToValidates[0].value"
                                         :label="$t('proccess.fields.nestedValues')"
                                         :required="!rule.validateOwn"
                                         :rules="!rule.validateOwn ? requireRules : []"
                                         :textFieldProps="{ outlined: true, label: $t('proccess.fields.nestedValues') }"
                          />
                        </v-col>
                        <v-col cols="2" v-else-if="!rule.validateOwn">
                          <v-text-field
                            dense
                            flat
                            outlined
                            color="primary"
                            :disabled="true"
                          >
                            <template v-slot:label>
                              {{ $t('proccess.fields.nestedValues') }}
                              <strong v-if="!rule.validateOwn" class="red--text">*</strong>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="2" v-show="rule.validateOwn">
                          <v-select
                            :items="rule.internalGroups"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            :label="$t('proccess.fields.ownGroups')"
                            v-model="rule.internalGroupSelected"
                            item-color="primary"
                            item-text="name"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            @change="getInternalStructures(rule)"
                            :disabled="loading.process[index] || !rule.validateOwn"
                          />
                        </v-col>
                        <v-col cols="2" v-show="rule.validateOwn">
                          <v-select
                            :items="rule.internalStructures"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :clearable="true"
                            v-model="rule.internalStructureSelected"
                            item-color="primary"
                            item-text="name"
                            return-object
                            dense
                            flat
                            outlined
                            color="primary"
                            :rules="rule.validateOwn ? requireRules : []"
                            :disabled="loading.process[index] || !rule.validateOwn"
                          >
                            <template v-slot:label>
                              {{ $t('structure.fields.structures') }}
                              <strong v-if="rule.validateOwn" class="red--text">*</strong>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-start align-center align-content-center">
                          <v-checkbox @change="updateOwnData($event, rule)" color="primary" class="ma-0 pa-0" v-model="rule.validateOwn" :label="$t('proccess.fields.ownData')" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider vertical  />
                    <v-col cols="2">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            :label="$t('proccess.fields.nValidations')"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="onlyPositiveNumberFormat"
                            v-model="rule.iterationsToValidate"
                            dense
                            flat
                            outlined
                            color="primary"
                            :disabled="loading.process[index]"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-btn
                  id="change"
                  :elevation="0"
                  color="error lighten-5"
                  small
                  style="border-radius: 0 0 0 .3em;"
                  tile
                  @mouseenter="isHover[index] = true"
                  @mouseleave="isHover[index] = false"
                  @click="removeRule(index)"
                >
                  <p :style="isHover ? 'width: 60px' : ''" class="ma-0 pa-0 error--text">{{ $t('general.buttons.delete') }}</p>
                  <v-icon color="error" style='font-size: 22px'>mdi-delete-outline</v-icon>
                </v-btn>
              </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-app-bar bottom elevation="0" color="transparent">
      <v-spacer></v-spacer>
      <v-btn :disabled="loadSave" small color="neuter" text @click="cancel">
        {{ $t('general.buttons.cancel') }}
      </v-btn>
      <v-btn :loading="loadSave" small elevation="0" class="mr-4 t-bw-primary--text" color="primary"
             @click="save({ showNotification: true })" :disabled="!valid || !validDateRange"
      >
        {{ $t('general.buttons.save') }}
      </v-btn>
    </v-app-bar>
  </span>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import i18n from '@/plugins/i18n';
import _ from "lodash";
import LanguajeService from "@/services/LanguajeService";
import { FieldTypes } from "@/module/configuration/structures/utils/field.types"
import ADatetimePicker from "@/components/common/ADatetimePicker.vue";
import ATimePicker from "@/components/common/ATimePicker.vue";
import ADatePicker from "@/components/common/ADatePicker.vue";
import moment from "moment/moment";

export default {
  name: "TabRulesProcess",
  components: {
    ADatetimePicker,
    ATimePicker,
    ADatePicker
  },
  data() {
    return {
      loadSave: false,
      rules: [],
      selectableStrucutres: ['LIST', 'LISTSIMPLE', 'PRODUCT', 'EQUIPMENT', 'INPUT', 'SEASON', 'OPERATOR', 'MEASUREMENTUNIT', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE', 'SEASONSIMPLE', 'MEASUREMENTUNITSIMPLE'],
      othersStrucutres: ['TEXT', 'NUMBER', 'LOTE', 'LACRE', 'QR', 'TIME', 'DATA', 'DATATIME'],
      strucutresMultiples: ['LIST', 'PRODUCT', 'EQUIPMENT', 'INPUT', 'SEASON', 'OPERATOR', 'MEASUREMENTUNIT'],
      strucutresSimples: ['LISTSIMPLE', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE', 'SEASONSIMPLE', 'MEASUREMENTUNITSIMPLE'],
      internalProcess: null,
      dateOperator: null,
      dateTimeOperators: FieldTypes['DATATIME'],
      oldProcessVersion: null,
      massBalanceConfiguration: {
        isActive: false,
        convertionFactor: 1.0
      },
      dateRange: -1,
      activeRangeDays: false,
      isBalanceActive: false,
      defaultRule: {
        id: null,
        companyId: null,
        name: i18n.t('proccess.fields.enterRuleName'),
        structureId: null,
        structureSelected: null,
        operator: null,
        groupSelected: null,
        internalGroupSelected: null,
        groupId: null,
        disposition: null,
        processToValidate: null,
        isInternal: {
          text: i18n.t('proccess.fields.internal').toUpperCase(),
          value: true
        },
        iterationsToValidate: 1,
        dataToValidates: [{value: null}],
        ownGroupId: null,
        ownStructureId: null,
        validateOwn: false,
        compress: true,
        processType: null,
        stage: null,
        groups: [],
        internalGroups: [],
        internalStructures: [],
        internalStructureSelected: null,
        structures: [],
        operators: [],
        nestedValues: [],
        isMultiple: false,
        process: []
      },
      type: [
        {
          text: i18n.t('proccess.fields.internal').toUpperCase(),
          value: true
        },
        {
          text: i18n.t('proccess.fields.external').toUpperCase(),
          value: false
        }
      ],
      ruleName: {},
      valid: false,
      validDateRange: false,
      ready: true,
      isHover: {},
      loading: {
        process: {}
      },
      profile: null,
      textProps: {
        persistentHint: true,
        hideDetails: true,
        dense: true,
        outlined: true,
        clearable: true,
        color: "primary",
        itemColor: "primary",
      }
    }
  },
  async created() {
    this.ready = false;
    this.profile = JSON.parse(localStorage.getItem("profile"));
    this.internalProcess = {...this.processSelected};

    this.oldProcessVersion = JSON.parse(localStorage.getItem('process:version'));

    const data = await this.fetchRulesByProcessId([this.oldProcessVersion, this.$toast]);

    if (data && data !== 'CONFIGURATION_NOT_PRESENT') {
      if (data.customRules && data.customRules.validationRules && data.customRules.validationRules.length > 0) {
        this.dateOperator = data.customRules.dateOperator;

        // Mapear las reglas personalizadas a promesas que resuelven las reglas con procesos fusionados
        const rulesWithMergedProcessesPromises = data.customRules.validationRules.map(async (rule) => {
          const processVersions = await this.fetchProcessByVersionList([[rule.processToValidate], this.$toast]);
          const mergedProcesses = this.mergeProcesses([...this.process], processVersions);

          // Retorna la regla con su correspondiente lista de procesos fusionada
          return {
            ...rule,
            process: mergedProcesses
          };
        });

        // Esperar a que todas las promesas se resuelvan
        const rulesWithMergedProcesses = await Promise.all(rulesWithMergedProcessesPromises);

        // Mapear sobre las reglas resueltas para finalizar el procesamiento
        const rulesPromises = rulesWithMergedProcesses.map(async (rule) => {
          const processToValidate = _.find(rule.process, p => p.processVersionId === rule.processToValidate);
          const data = await this.fetchProcessByVersionList([[processToValidate.processVersionId], this.$toast]);
          return {
            ...rule,
            dataToValidates: [...rule.dataToValidates],
            processToValidate: data[0],
            isInternal: rule.isInternal ? this.type[0] : this.type[1],
            compress: true,
          };
        });

        const rules = await Promise.all(rulesPromises);

        for (const r of rules) {
          if (r.isInternal.value) {
            r.processToValidate = {...this.processSelected};
            this.getInternalFilters(r)
          } else {
            this.getExternalFilters(r)
          }
          if (r.processToValidate) {

            this.getGroups(r)
            r.groupSelected = _.find(r.groups, g => g.id === r.groupId)

            await this.$nextTick();

            this.getStructures(r)
            r.structureSelected = _.find(r.structures, s => s.id === r.structureId)

            await this.$nextTick();

            if (r.structureSelected) {
              this.getOperatorsAndNestedValues(r);
            }

            await this.$nextTick();

            this.getInternalGroups(r)
            r.internalGroupSelected = _.find(r.internalGroups, g => g.id === r.ownGroupId)

            await this.$nextTick();

            if (r.internalGroupSelected) {
              this.getInternalStructures(r)
              r.internalStructureSelected = _.find(r.internalStructures, s => s.id === r.ownStructureId)
            }
          }
        }

        this.$set(this, 'rules', rules);
      }

      // Configuración del balance de masa si está disponible
      if (data.ownRules) {
        if (data.ownRules.massBalanceConfiguration) {
          this.isBalanceActive = this.isProcessing ? data.ownRules.massBalanceConfiguration.isActive : false;
          this.massBalanceConfiguration = {
            ...data.ownRules.massBalanceConfiguration,
            isActive: this.isBalanceActive
          };
        }

        this.dateRange = data.ownRules.dateRange !== -1 ? data.ownRules.dateRange : null;
        this.activeRangeDays = !!this.dateRange;
      }

      // Marcar que el componente está listo después de la inicialización
      this.ready = true;
    } else {
      this.ready = true;
    }
  },
  watch: {
    processSelected(val) {
      this.internalProcess = {...val};
    },
    isBalanceActive(val) {
      this.$set(this.massBalanceConfiguration, 'isActive', val);
    }
  },
  computed: {
    ...mapGetters({
      processTypeEnum: "proccess/processTypeEnum",
      requireRules: 'general/requireRules',
      requireAndMinMax: 'general/requireAndMinMax',
      onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
      onlyCurrencyFormat: 'general/onlyCurrencyFormat',
      config: 'general/config'
    }),
    process: {
      get() {
        return this.$store.getters['proccess/proccess']
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCCESS_LIST', val)
      },
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
        ? this.config.company.traceability.structure.number.afterPoint
        : 3
    },
    processSelected: {
      get() {
        return this.$store.getters['proccess/procces'];
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCESS', val)
      }
    },
    storagesTypesByCompany() {
      return _.filter(this.$store.getters['storage_type/storagesTypesByCompany'], {isActive: true});
    },
    isProcessing() {
      return localStorage.getItem('process:type') === 'PROCESSING';
    }
  },
  methods: {
    ...mapActions({
      fetchProcessById: "proccess/getProcessById",
      fetchRulesByProcessId: "proccess/fetchRulesByProcessId",
      fetchProcessByVersionList: "proccess/fetchProcessByVersionList",
      saveRules: "proccess/saveRules"
    }),
    handleRangeDaysSwitch() {
      this.dateRange = null;
      if (!this.activeRangeDays) {
        this.validDateRange = true;
      }
    },
    filteredProcess(rule) {
      return _.filter(rule.process, (item) => {
        let matchProcessType = true;
        let matchStage = true;

        if (rule.processType != null) {
          matchProcessType = item.processType === rule.processType;
        }
        if (rule.stage != null && item.sdt_to_show && item.sdt_to_show.id) {
          matchStage = item.sdt_to_show.id === rule.stage.storage_device_type_id;
        }

        return matchProcessType && matchStage;
      });
    },
    mergeProcesses(processList, processVersionsList) {
      const merged = [];

      // Añade los elementos de processList a merged con su nombre traducido
      processList.forEach(process => {
        merged.push({
          ...process,
          proccess_name: this.translate(process.language_key)
        });
      });

      // Revisa cada elemento en processVersionsList y añade "Current" si es necesario
      processVersionsList.forEach(pv => {
        const foundInMerged = merged.find(process => process.processVersionId === pv.processVersionId);
        if (!foundInMerged) {
          merged.push({
            ...pv,
            proccess_name: `${this.translate(pv.language_key)} (V${pv.version})`,
          });
        }
      });

      // Actualiza this.process con la nueva lista fusionada
      return merged;
    },

    updateOwnData(e, rule) {
      if (!e) {
        rule.internalGroupSelected = null;
        rule.ownGroupId = null;
        rule.internalStructureSelected = null;
        rule.ownStructureId = null;
      }
    },
    onChangeType(rule) {
      if (rule.isInternal.value) {
        this.processSelected = {...this.internalProcess}

        rule.stage = null;
        rule.processType = null;
        rule.processToValidate = {...this.processSelected};

        this.getGroups(rule);
      }
    },
    async onSelectedProcess(rule, index) {
      this.clearData(rule);
      if (rule.processToValidate) {
        this.$set(this.loading.process, index, true)
        const data = await this.fetchProcessByVersionList([[rule.processToValidate.processVersionId], this.$toast])

        if (data && data.length === 1) {
          rule.processToValidate = data[0]
        }

        this.$set(this.loading.process, index, false)

        this.getGroups(rule)
        this.getStructures(rule)
        this.getInternalGroups(rule)
        this.getInternalStructures(rule)
      }
    },
    removeRule(index) {
      this.rules.splice(index, 1);
    },
    clearData(rule) {
      rule.groupSelected = null;
      rule.structureSelected = null;
      rule.internalGroupSelected = null;
      rule.internalStructureSelected = null;
      rule.operator = null;
      rule.groups = [];
      rule.internalGroups = [];
      rule.operators = [];
      rule.structures = [];
      rule.internalStructures = [];
      rule.nestedValues = [];
      rule.dataToValidates = [{value: null}]
      rule.isMultiple = false
    },
    addRule() {
      this.rules.unshift({
        ...this.defaultRule,
        processToValidate: {...this.internalProcess},
        process: [...this.process]
      });

      this.clearData(this.rules[0]);

      this.getGroups(this.rules[0]);
      this.getInternalGroups(this.rules[0]);
      this.getStructures(this.rules[0]);
      this.getInternalStructures(this.rules[0]);
    },
    editRuleName(index) {
      this.$set(this.ruleName, index, true);
    },
    saveRuleName(index) {
      this.$set(this.ruleName, index, false);
    },
    async save(opts) {
      const rules = [...this.rules.map((rule, index) => {
        return {
          ..._.omit(rule, ['process', 'compress', 'stage', 'groups', 'structures', 'operators', 'nestedValues', 'isMultiple', 'structureSelected', 'groupSelected', 'internalGroupSelected', 'internalGroups', 'internalStructureSelected', 'internalStructures']),
          companyId: this.profile.company_id,
          disposition: index,
          processToValidate: rule.processToValidate.processVersionId,
          isInternal: rule.isInternal.value,
          structureId: rule.structureSelected.id,
          ownStructureId: rule.internalStructureSelected ? rule.internalStructureSelected.id : null,
          groupId: rule.groupSelected ? rule.groupSelected.id : null,
          ownGroupId: rule.internalGroupSelected ? rule.internalGroupSelected.id : null,
          processType: rule.processToValidate.processType,
          dataToValidates: rule.isMultiple ? rule.dataToValidates.map(v => ({
            value: v.value
          })) : rule.dataToValidates
        }
      })]

      const result = {
        companyId: this.profile.company_id,
        newProcessVersionId: this.internalProcess.processVersionId,
        oldProcessVersionId: this.oldProcessVersion || this.internalProcess.processVersionId,
        customRules: {
          dateOperator: this.dateOperator || "ALL_DATES",
          validationRules: [...rules]
        },
        ownRules: {
          massBalanceConfiguration: Object.assign({}, this.massBalanceConfiguration),
          dateRange: this.activeRangeDays ? this.dateRange > 0 ? this.dateRange : -1 : -1
        }
      }

      await this.saveRules([result, this.$toast, opts]);

      this.cancel();
    },
    clearLocalStorage() {
      localStorage.removeItem('process:sdt')
      localStorage.removeItem('process:type')
      localStorage.removeItem('process:version')
    },
    cancel() {
      this.rules = [];
      this.$root.$emit('clear:process-data');
      this.clearLocalStorage();
    },
    getGroups(rule) {
      this.$set(rule, 'groups', rule.processToValidate.processStructureOrganized.groups.map(g => ({
        ...g,
        name: this.translate(g.languageKey)
      })));
    },
    getInternalFilters(rule) {
      this.$set(rule, 'processType', rule.processToValidate.processType.value?rule.processToValidate.processType.value:rule.processToValidate.processType);
      this.$set(rule, 'stage', this.storagesTypesByCompany.find(s => s.storage_device_type_id === rule.processToValidate.sdt_to_show.storage_device_type_id));
      this.filteredProcess(rule);
    },
    getExternalFilters(rule) {
      this.$set(rule, 'processType', rule.processToValidate.processType.value?rule.processToValidate.processType.value:rule.processToValidate.processType);
      this.$set(rule, 'stage', this.storagesTypesByCompany.find(s => s.storage_device_type_id === rule.processToValidate.sdt_to_show.storageDeviceId));
      this.filteredProcess(rule);
    },
    getInternalGroups(rule) {
      this.$set(rule, 'internalGroups', this.internalProcess.processStructureOrganized.groups.map(g => ({
        ...g,
        name: this.translate(g.languageKey)
      })));
    },
    getStructures(rule) {
      if (rule.groupSelected) {
        this.$set(rule, 'structures', [...rule.groupSelected.rows[0].values.map(v => ({
          ...v.structure,
          name: this.translate(v.structure.language_key),
          dataToSelect: [...v.structure.dataToSelect]
        }))])
      } else {
        this.$set(rule, 'structures', [...rule.processToValidate.processStructureOrganized.structures.map(v => ({
          ...v.structure,
          name: this.translate(v.structure.language_key),
          dataToSelect: [...v.structure.dataToSelect]
        }))])

        rule.operators = [];
        rule.nestedValues = [];
        rule.dataToValidates = [{value: null}];
      }
    },
    getInternalStructures(rule) {
      if (rule.internalGroupSelected) {
        this.$set(rule, 'internalStructures', [...rule.internalGroupSelected.rows[0].values.map(v => ({
          ...v.structure,
          name: this.translate(v.structure.language_key),
          dataToSelect: [...v.structure.dataToSelect]
        }))]);
      } else {
        this.$set(rule, 'internalStructures', [...rule.processToValidate.processStructureOrganized.structures.map(v => ({
          ...v.structure,
          name: this.translate(v.structure.language_key),
          dataToSelect: [...v.structure.dataToSelect]
        }))]);
      }
    },
    getOperatorsAndNestedValues(rule) {
      const type = rule.structureSelected ? rule.structureSelected.structure_type : null;

      console.log(rule)

      if (type) {
        this.$set(rule, 'operators', FieldTypes[type]);
        this.$set(rule, 'isMultiple', this.strucutresMultiples.includes(type));

        if (this.selectableStrucutres.includes(type)) {
          rule.dataToValidates = _.filter(rule.dataToValidates, (d) => { return d.value !== null });

          if (['LIST', 'LISTSIMPLE'].includes(type)) {
            rule.nestedValues = _.map(rule.structureSelected.data_source, (v) => {
              return {
                ...v,
                value: this.translate(v.name)
              }
            })
          } else {
            rule.nestedValues = _.map(rule.structureSelected.dataToSelect, (v) => {
              return {
                ...v,
                value: this.translate(v.name)
              }
            })
          }
        }
      } else {
        rule.dataToValidates = [{value: null}];
        rule.nestedValues = [];
      }
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
  },
  destroyed() {
    this.clearLocalStorage();
  }
}
</script>

<style scoped>
#change p {
  width: 0;
  overflow: hidden;
  transition: all .3s ease-in-out;
}

#change:hover p {
  width: 130px;
  max-width: 100%;
  overflow-x: hidden;
}

button#change {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<template>
    <v-row>
      <v-col cols="9">
        <!--DATETIME-->
        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                      v-if="(structure.structure_type==='DATATIME')"
                      type="datetime"
                      v-model="processDetails"
                      :label="structure.details_name"
                      prepend-icon="mdi-calendar"
                      disabled
                      class="required"
                      persistent-hint
                      small
                      outlined
                      dense
                      :ref="`datetimeFilter_${refKey}`"
        />

        <!--DATE-->
        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                      v-if="(structure.structure_type==='DATA')"
                      type="date"
                      v-model="processDetails"
                      :label="structure.details_name"
                      prepend-icon="mdi-calendar"
                      disabled
                      class="required"
                      persistent-hint
                      small
                      outlined
                      dense
                      :ref="`dateFilter_${refKey}`"
        />

        <!--TIME-->
        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                      v-if="(structure.structure_type==='TIME')"
                      type="time"
                      v-model="processDetails"
                      :label="structure.details_name"
                      prepend-icon="mdi-clock-time-four-outline"
                      disabled
                      class="required"
                      persistent-hint
                      small
                      outlined
                      dense
                      :ref="`timeFilter_${refKey}`"
        />

        <!--LISTMULTIPLE-->
        <v-combobox :no-data-text="$t('general.fields.noDataAvailable')"
                    v-model="processDetails"
                    clearable
                    :label="structure.details_name"
                    color="primary"
                    v-if="structure.structure_type==='LIST' || structure.structure_type==='LISTSIMPLE'"
                    persistent-hint
                    small
                    outlined
                    dense
                    multiple
                    :rules="selectRule"
                    :ref="`listFilter_${refKey}`"
        >
          <template
              v-slot:selection="{ attrs, item, select, selected }">
            <v-chip small v-bind="attrs"
                    :input-value="selected"
                    @click="select">{{ item }}
            </v-chip>
          </template>
        </v-combobox>

        <!--PRODUCTMULTIPLE-->
        <a-select
          :refer="`sub_productsFilter_${refKey}`"
          @data:change="processDetails = $event"
          @select:all="updateSelect($event)"
          :field-name="structure.details_name"
          nomenclator="product"
          :structure="structure"
          :show-selected="true"
          v-if="structure.structure_type==='PRODUCT' || structure.structure_type==='PRODUCTSIMPLE'"
          :edited-index="editedIndex"
        />

        <!--EQUIPMENTMULTIPLE-->
        <a-select
          :refer="`sub_equipmentsFilter_${refKey}`"
          @data:change="processDetails = $event"
          @select:all="updateSelect($event)"
          :field-name="structure.details_name"
          nomenclator="equipment"
          :structure="structure"
          :show-selected="true"
          v-if="structure.structure_type==='EQUIPMENT' || structure.structure_type==='EQUIPMENTSIMPLE'"
          :edited-index="editedIndex"
        />

        <!--OPERATORMULTIPLE-->
        <a-select
          :refer="`sub_operatorsFilter_${refKey}`"
          @data:change="processDetails = $event"
          @select:all="updateSelect($event)"
          :field-name="structure.details_name"
          nomenclator="operator"
          :structure="structure"
          :show-selected="true"
          v-if="structure.structure_type==='OPERATOR' || structure.structure_type==='OPERATORSIMPLE'"
          :edited-index="editedIndex"
        />

        <!--INPUTMULTIPLE-->
        <a-select
          :refer="`sub_inputsFilter_${refKey}`"
          @data:change="processDetails = $event"
          @select:all="updateSelect($event)"
          :field-name="structure.details_name"
          nomenclator="input"
          :structure="structure"
          :show-selected="true"
          v-if="structure.structure_type==='INPUT' || structure.structure_type==='INPUTSIMPLE'"
          :edited-index="editedIndex"
        />

        <!--MEASUREMENTUNITMULTIPLE-->
        <Autocomplete
            :data="processDetails"
            :refer="`sub_allUnitsFilter_${refKey}`"
            :items="allUnits"
            :label="structure.details_name"
            :text="'unit_name'"
            :value="'id'"
            :rules="selectRule"
            v-if="structure.structure_type==='MEASUREMENTUNIT' || structure.structure_type==='MEASUREMENTUNITSIMPLE'"
            @data:change="processDetails = $event"
            :show-selected="true"
            :edited-index="editedIndex"
        >
        </Autocomplete>

        <!--SEASONMULTIPLE-->
        <a-select
          :refer="`sub_seasonsFilter_${refKey}`"
          @data:change="processDetails = $event"
          @select:all="updateSelect($event)"
          :field-name="structure.details_name"
          nomenclator="season"
          :structure="structure"
          :show-selected="true"
          v-if="structure.structure_type==='SEASON' || structure.structure_type==='SEASONSIMPLE'"
         :edited-index="editedIndex"
        />

        <!--TEXT-->
        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                      v-if="structure.structure_type==='TEXT' ||
                            structure.structure_type==='LACRE'||
                            structure.structure_type==='LOTE' ||
                            structure.structure_type==='QR'"
                      color="primary"
                      v-model="processDetails"
                      :label="structure.details_name"
                      disabled
                      class="required"
                      persistent-hint
                      small
                      outlined
                      dense
                      :ref="`textFilter_${refKey}`"
        />

        <!--CURRENCY-->
        <v-currency-field
            :default-value="null"
            :value-as-integer="false"
            :decimal-length="decimalFormat"
            :auto-decimal-mode="false"
            disabled
            locale="en"
            v-if="structure.structure_type==='NUMBER'"
            color="primary"
            :label="structure.details_name"
            v-model="processDetails"
            class="required"
            persistent-hint
            small
            outlined
            dense
            :ref="`numberFilter_${refKey}`"
        >
        </v-currency-field>

      </v-col>
      <v-col cols="3">
        <slot name="actions" />
        <v-checkbox v-if="isDefaultStructure(structure.structureCode)"
                    color="primary"
                    :value="true"
                    v-model="structure.is_required = true"
                    :label="$t('proccess.fields.required')"
                    :disabled="isDefaultStructure(structure.structureCode)"
                    class="mt-1"
        />
        <v-checkbox v-else
                    color="primary"
                    v-model="structure.is_required"
                    :label="$t('proccess.fields.required')"
                    class="mt-1"
        />
      </v-col>
    </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Autocomplete from "@/components/Autocomplete.vue";
import _ from "lodash";
import LanguajeService from "@/services/LanguajeService";
import ASelect from "@/components/common/AAutocomplete/ASelect.vue";

const NOMENCLATURES = ['PRODUCT', 'EQUIPMENT', 'INPUT', 'SEASON', 'OPERATOR', 'MEASUREMENTUNIT', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE', 'SEASONSIMPLE', 'MEASUREMENTUNITSIMPLE'];
const OTHERS = ['TEXT', 'NUMBER', 'LOTE', 'LACRE', 'QR', 'TIME', 'DATA', 'DATATIME'];

export default {
  name: "StructureComponent",
  components: {ASelect, Autocomplete},
  props: {
    structure: {
      type: Object,
      require: true
    },
    config: {
      type: Object,
      require: true
    },
    refKey: {
      type: String|Number,
      require: true
    },
    editedIndex: {
      type: String|Number,
      require: false,
      default: -1
    }
  },

  data: () => ({
    processDetails: [],
    loading: false,
    type: null,
    field: null,
    selectAll: false
  }),

  watch: {
    processDetails(value) {
      this.defineType(this.structure.structure_type);

      if (this.editedIndex === -1) {
        let data = JSON.parse(localStorage.getItem('process:data')) || [];

        _.forEach(this.$refs, (e, k) => {
            let index = data.findIndex(item => item.hasOwnProperty(k));

            if (NOMENCLATURES.includes(this.structure.structure_type)) {
                let newData = {
                    ...value,
                    [this.type]:  this.translate(value.language_key)
                };

                if (index !== -1) {
                    // Si la clave existe, actualiza el valor
                    data[index][k] = newData;
                } else {
                    // Si la clave no existe, empuja un nuevo objeto
                    let newObj = {};
                    newObj[k] = newData;
                    data.push(newObj);
                }

            } else {

                if (index !== -1) {
                    // Si la clave existe, actualiza el valor
                    data[index][k] = value;
                } else {
                    // Si la clave no existe, empuja un nuevo objeto
                    let newObj = {};
                    newObj[k] = value;
                    data.push(newObj);
                }

            }
        });

        localStorage.setItem('process:data', JSON.stringify(data));

        // _.forEach(this.$refs, (e, k) => {
        //   if (NOMENCLATURES.includes(this.structure.structure_type))
        //     localStorage.setItem(k, JSON.stringify({
        //       ...value,
        //       [this.type]:  this.translate(value.language_key)
        //     }))
        //   else
        //     localStorage.setItem(k, JSON.stringify(value))
        // })
      }

      this.$emit("update:process-details", value)
    },
    loading(value) {
      this.$emit("update:loading", value)
    }
  },

  mounted() {
    this.defineType(this.structure.structure_type);
    this.$root.$on('initNomenclatorData', (s) => {
      this.initNomenclatorData(s)
    })

    this.$root.$on('clear:process-details', () => {
      this.$set(this, 'processDetails', [])
    })

    if (this.editedIndex === -1) {
      const data = JSON.parse(localStorage.getItem('process:data'))
      if (data && data.length > 0) {
        _.forEach(data, (d, dk) => {
          _.forEach(this.$refs, (e, ek) => {
          if (d && d[ek]) {
            this.$set(this.structure, this.field, d[ek])
            this.$set(this, 'processDetails', d[ek])
          }
        })
        })
      }
    }
  },

  created() {
    if (this.editedIndex !== -1)
      this.structureAddForEdit(this.structure);
    else {
      this.setStructureDefaultValue(this.structure)
    }
  },

  computed: {
    ...mapGetters({
      products: 'product/products',
      operators: 'operator/operators',
      equipments: 'equipment/equipments',
      seasons: 'season/seasons',
      inputs: 'input/inputs',
      allUnits: 'units/allUnits',
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      selectRule: "general/selectRule",
    }),
    isDefaultGroup() {
      return (code) => ['GI', 'GO', 'REEN'].includes(code)
    },
    isDefaultStructure() {
      return (code) => ['CI', 'CO', 'C', 'PI', 'PO', 'P', 'MUI', 'MUO', 'MU', 'LI', 'LO', 'L', 'S'].includes(code)
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
  },

  methods: {
    ...mapActions({
      fetchSelectedData: 'proccess/fetchNomenclatorData'
    }),
    updateSelect(e) {
      this.selectAll = e;
      this.$emit("update:selected-all", this.selectAll)
    },
    defineType(type) {
      switch(type) {
        case "PRODUCT":
        case "PRODUCTSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'product_name'
          break;
        case "SEASON":
        case "SEASONSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'season_name'
          break;
        case "INPUT":
        case "INPUTSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'input_name'
          break;
        case "EQUIPMENT":
        case "EQUIPMENTSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'equipment_name'
          break;
        case "OPERATOR":
        case "OPERATORSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'operator_name'
          break;
        case "MEASUREMENTUNIT":
        case "MEASUREMENTUNITSIMPLE":
          this.field = 'dataToSelect'
          this.type = 'unit_name'
          break;
        case "LIST":
        case "LISTSIMPLE":
          this.field = 'data_source'
          break;
      }
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
    initNomenclatorData(s) {
      switch (s.structure_type) {
        case "PRODUCT":
        case "PRODUCTSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              product_name: this.translate(data_value.name)
            })
          });

          break;
        case "SEASON":
        case "SEASONSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              season_name: this.translate(data_value.name)
            });
          });

          break;
        case "INPUT":
        case "INPUTSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              input_name: this.translate(data_value.name)
            });
          });

          break;
        case "EQUIPMENT":
        case "EQUIPMENTSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              equipment_name: this.translate(data_value.name)
            });
          });

          break;
        case "OPERATOR":
        case "OPERATORSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              operator_name: this.translate(data_value.name)
            });
          });

          break;
        case "MEASUREMENTUNIT":
        case "MEASUREMENTUNITSIMPLE":
          _.forEach(s.dataToSelect, (data_value, key) => {
            this.$set(this.processDetails, key, {
              ...data_value,
              unit_name: this.translate(data_value.name)
            });
          });
          break;
      }
    },
    setStructureDefaultValue(s) {
      if (['LIST', 'LISTSIMPLE'].includes(s.structure_type)) {
        _.forEach(s.data_source, (source_value, key) => {
          if (source_value && (source_value.name && source_value.name.length > 0))
            this.$set(this.processDetails, key, this.translate(source_value.name));
          else
            this.$set(this, 'processDetails', [])
        });
      }

      if (NOMENCLATURES.includes(s.structure_type)) {
        if (s.dataToSelect && s.dataToSelect.length > 0)
          this.initNomenclatorData(s)
        else
          this.$set(this, 'processDetails', [])
      }

      if (OTHERS.includes(s.structure_type)) {
        if (s.structure_type === "NUMBER")
          this.processDetails = 0
        else
          this.$set(this, 'processDetails', '')
      }
    },
    structureAddForEdit(s) {
      if (['LIST', 'LISTSIMPLE'].includes(s.structure_type)) {
        _.forEach(s.data_source, (source_value, key) => {
          this.$set(this.processDetails, key, this.translate(source_value.name))
        });
      }

      if (NOMENCLATURES.includes(s.structure_type)) {
        if (s.dataToSelect && s.dataToSelect.length > 0)
          this.initNomenclatorData(s)
        else
          this.$set(this, 'processDetails', [])
      }

      if (OTHERS.includes(s.structure_type)) {
        if (s.structure_type === "NUMBER")
          this.processDetails = 0
        else
          this.$set(this, 'processDetails', '')
      }
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    async searchNomenclator(event, nomenclator) {
      this.loading = true

      const filters = {
        stringFilter: event || "",
        nomenclatorType: nomenclator
      }

      if (this.structure.dataToSelect && this.structure.dataToSelect.length > 0) {
        if (filters && filters.stringFilter !== "")
          filters.stringFilter = [event];

        filters.stringFilter = [...filters.stringFilter, ..._.map(this.structure.dataToSelect, d => this.translate(d.name))];
      }

      await this.fetchSelectedData([filters, this.$toast])
          .finally(() => {
            this.loading = false
          })
    },
  },

  destroyed() {
    this.$root.$off('initNomenclatorData');
    this.$root.$off('clear:process-details');
  }
}
</script>

<style scoped>

</style>
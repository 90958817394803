<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="ma-0 pa-0 mt-4">
          <v-card elevation="0">
            <v-overlay
              v-show="loading"
              color="white"
              opacity="0.9"
              style="z-index: 2"
            >
              <v-progress-circular
                style="left: 150px"
                class="mx-auto"
                size="50"
                indeterminate
                color="primary"
              />
            </v-overlay>
            <v-tabs  color="neuter" background-color="gray" v-model="tabs" hide-slider height="60">
              <v-tab
                class="ma-0 py-0 px-4"
              >
                {{ $t('proccess.titles.main') }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
                :disabled="!processSelected.id"
              >
                {{ $t('proccess.titles.rules') }}
              </v-tab>

              <v-tabs-items v-model="tabs">
                <v-tab-item :value="0">
                  <v-card flat>
                    <v-card-text>
                      <TabMainProcess
                        ref="tabMainProcess"
                        :action="action"
                        :item="editedItem"
                        @change:tab="tabs = $event"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="1">
                  <v-card flat>
                    <v-card-text>
                      <TabRulesProcess
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import LanguageService from "@/services/LanguajeService"
import TabMainProcess from "@/module/configuration/process/components/tabs/items/TabMainProcess.vue";
import TabRulesProcess from "@/module/configuration/process/components/tabs/items/TabRulesProcess.vue";

const PROFILE = JSON.parse(localStorage.getItem("profile"))

export default {
  components: {
    TabRulesProcess,
    TabMainProcess,
  },
  props: {
    uuid: String,
    action: String,
  },
  data: () => ({
    tabs: 0,
    loading: false,
    turfDefaultUnit: null,
    profile: null,
    editedIndex: null,
    forms: {
      main: false,
      rules: false
    },
    editedItem: {},
  }),

  async created() {},

  mounted() {},

  computed: {
    ...mapGetters({
      languageStructure: "general/languageStructure",
    }),
    valid() {
      return this.forms.main
    },
    processSelected: {
      get() {
        return this.$store.getters['proccess/procces'];
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCESS', val)
      }
    },
  },

  methods: {
    ...mapActions({}),
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    back() {
      localStorage.removeItem("processItem")
      this.$store.commit('proccess/SET_ORIGINAL_STATE_PROCESS');
      this.$router.push('/nomenclators/proccess');
    },
  },
}
</script>

<style scoped lang="scss">
  :deep(.v-tabs-bar) {
    background-color: transparent;
    margin: 0 15px;
    border-radius: 0.3em;
  }

  :deep(.v-slide-group__wrapper) {
    display: grid;
    justify-content: start;
    border-radius: 0.3em;
    margin: 0;
  }

  :deep(.theme--light.v-tabs:not(.v-tabs--vertical) .v-tab) {
    margin: 0 !important;
    padding: 0 10px !important;
    font-size: 14px;
  }

  .v-tab--active {
    color: var(--v-t-bw-primary-base) !important;
    background-color:var(--v-primary-base) !important;
  }
</style>
